<template>
  <div>
    <div v-if="currentFile" class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>

    <div :class="['f-uploader', { uploading: isUploading }]">
      <label class="toolbar d-flex align-items-center">
        <label class="btn btn-info mr-3">
          <div class="d-flex align-items-center">
            <i class="fal fa-plus mr-2"></i>
            <div>Dosya Ekle</div>
          </div>
          <input
            ref="fileInput"
            type="file"
            @change="onFileSelect"
            :multiple="multiple"
            :accept="accept"
            :disabled="isDisabled"
          />
        </label>
        <div v-if="placeholder && !hasFiles" clas="placeholder">
          {{ placeholder }}
        </div>
        <div v-if="message" v-html="message"></div>
      </label>
      <ul class="files" v-if="hasFiles">
        <li
          v-for="(file, index) in files"
          :key="file"
          :class="[
            'file',
            {
              'has-error': file.hasError,
              completed: file.isUploaded,
              uploading: file.isUploading
            }
          ]"
        >
          <div :class="['file-info']">
            <div class="file-type">
              <i
                class="fal fa-file"
                v-if="!file.hasError && !file.isUploaded"
              ></i>
              <i
                class="fas fa-exclamation-circle"
                v-if="file.hasError && !file.isUploaded"
              ></i>
              <i
                class="fas fa-check"
                v-if="!file.hasError && file.isUploaded"
              ></i>
            </div>
            <div class="mr-auto">
              <div class="file-name">{{ file.name }}</div>
              <div class="file-description">
                <div v-if="!file.hasError">{{ file.formattedSize }}</div>
                <div v-if="file.hasError">{{ file.error.message }}</div>
              </div>
            </div>
            <div class="f-progress" v-if="file.isUploading">
              {{ file.progress }}%
            </div>
            <div class="remove">
              <i class="far fa-times" @click="onRemove(index)"></i>
            </div>
          </div>

          <p-progress-bar
            :value="file.progress"
            :showValue="false"
            class="f-progress-bar"
            v-if="file.isUploading"
          ></p-progress-bar>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { makeId } from "../shared";
import apiClient from "../services";
import PProgressBar from "primevue/progressbar";

export default {
  components: {
    PProgressBar
  },
  name: "upload-files",
  emits: [
    "change",
    "select",
    "before-upload",
    "progress",
    "upload",
    "error",
    "upload-error",
    "before-send",
    "clear",
    "remove"
  ],
  props: {
    id: {
      type: String,
      default() {
        return makeId();
      }
    },
    name: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    placeholder: String,
    auto: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    formData: Object,
    maxFileSize: {
      type: Number,
      default: null
    },
    invalidFileSizeMessage: {
      type: String,
      default: "Dosya boyutu en fazla {0} olabilir."
    },
    fileLimit: {
      type: Number,
      default: null
    },
    invalidFileLimitMessage: {
      type: String,
      default: "En fazla {0} dosya yükleyebilirsiniz"
    },
    allowedExtensions: {
      type: Array,
      default: undefined
    },
    invalidFileExtensionMessage: {
      type: String,
      default: "İzin verilmeyen dosya türü"
    }
  },
  data() {
    return {
      files: [],
      currentFile: undefined,
      progress: 0,
      message: "",
      messages: [],
      fileInfos: []
    };
  },
  methods: {
    onFileSelect() {
      if (event.type !== "drop" && this.isIE11() && this.duplicateIEEvent) {
        this.duplicateIEEvent = false;
        return;
      }

      this.messages = [];
      this.files = this.files || [];

      let files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

      for (let file of files) {
        if (!this.isFileSelected(file)) {
          let fFile = this.createFile(file);

          this.files.push(fFile);
        }
      }

      if (this.auto && this.hasFiles) {
        this.upload();
      }

      if (event.type !== "drop" && this.isIE11()) {
        this.clearIEInput();
      } else {
        this.clearInputElement();
      }

      this.$emit("select", { id: this.id, files: this.files, event });
      this.onChange(event);
    },
    onChange(event) {
      this.$emit("change", {
        id: this.id,
        files: this.files,
        event
      });
    },
    onRemove(index) {
      this.clearInputElement();
      const removedFile = { ...this.files[index] };
      this.files.splice(index, 1);
      this.files = [...this.files];
      this.$emit("remove", {
        id: this.id,
        file: removedFile,
        files: this.files
      });
    },
    isIE11() {
      return !!window["MSInputMethodContext"] && !!document["documentMode"];
    },
    createFile(inputFile) {
      const extension = inputFile.name
        .split(".")
        .pop()
        .toLowerCase();

      const file = {
        file: inputFile,
        name: inputFile.name,
        extension,
        formattedSize: this.formatSize(inputFile.size),
        progress: 0,
        uploadedSize: 0,
        isUploaded: false,
        uploadResult: null,
        isUploading: false
      };

      if (
        this.files.filter((x) => !x.error || x.error.type !== "validation")
          .length >= this.fileLimit
      ) {
        file.error = {
          type: "validation",
          message: this.invalidFileLimitMessage.replace("{0}", this.fileLimit)
        };
      } else if (this.maxFileSize && file.size > this.maxFileSize) {
        file.error = {
          type: "validation",
          message: this.invalidFileSizeMessage.replace(
            "{0}",
            this.formatSize(this.maxFileSize)
          )
        };
      } else if (
        this.allowedExtensions &&
        this.allowedExtensions.length > 0 &&
        !this.allowedExtensions.includes("." + file.extension)
      ) {
        file.error = {
          type: "validation",
          message: this.invalidFileExtensionMessage
            .replace("{0}", file.extension)
            .replace("{1}", this.allowedExtensions.join())
        };
      }

      file.hasError = file.error && file.error.type === "validation";

      return file;
    },
    getUploadedFiles() {
      return this.files.filter((file) => file.isUploaded);
    },
    canUpload(file) {
      return (
        !file.isUploaded && (!file.hasError || file.error.type !== "validation")
      );
    },
    upload() {
      this.progress = 0;

      const filesToUpload = this.files.filter(this.canUpload);

      const totalSize =
        filesToUpload && filesToUpload.length > 0
          ? filesToUpload.reduce(
              (accumulator, currentFile) => accumulator + currentFile.file.size
            )
          : 0;

      let totalUploaded = 0;

      for (let file of this.files) {
        if (this.canUpload(file)) {
          file.isUploading = true;
          this.$emit("before-upload", {
            id: this.id,
            file: file,
            files: this.files
          });

          apiClient
            .upload(
              this.url,
              file.file,
              (event) => {
                file.uploadedSize = event.loaded;
                file.progress = Math.round((100 * event.loaded) / event.total);
                totalUploaded += event.loaded;

                this.progress = Math.round((100 * totalUploaded) / totalSize);

                this.$emit("progress", this.progress, this.files);
              },
              this.formData
            )
            .then((response) => {
              if (response && Array.isArray(response) && response.length > 0) {
                file.uploadResult = response[0];
                file.isUploaded = true;
                file.hasError = false;
                file.progress = 100;
                file.error = 0;
                file.isUploading = false;
                this.$emit("upload", {
                  id: this.id,
                  file,
                  files: this.files
                });
              }
            })
            .catch(() => {
              file.progress = 0;
              file.error = {
                type: "network",
                message: "Dosya yüklenemedi"
              };
              file.hasError = true;
              file.isUploading = false;

              this.$emit("upload-error", { fileInputId: this.id, file });
            });
        }
      }
    },
    createFileId({ name, type, size }) {
      return `${name}${type}${size}`;
    },
    isFileSelected(file) {
      if (this.hasFiles) {
        return this.files.some(
          (f) => this.createFileId(f.file) === this.createFileId(file)
        );
      }
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    clearInputElement() {
      this.$refs.fileInput.value = "";
    },
    clearIEInput() {
      if (this.$refs.fileInput) {
        this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
        this.$refs.fileInput.value = "";
      }
    }
  },
  computed: {
    hasFiles() {
      return this.files && this.files.length > 0;
    },
    isDisabled() {
      return this.disabled || this.isUploading;
    },
    isUploading() {
      return this.files.some((f) => f.isUploading);
    }
  },
  mounted() {
    // apiClient.getFiles().then((response) => {
    //   this.fileInfos = response.data;
    // });
  }
};
</script>
<style scoped lang="scss">
@import "../scss/_variables";

$gap: 10px;
$gap-sm: $gap * 0.8;

.f-uploader {
  border: solid 2px $input-border-color;
  border-radius: 8px;
  overflow: hidden;

  input[type="file"] {
    display: none;
  }

  .toolbar {
    padding: $gap;
    background: #f5f5f5;
    margin: 0;

    label {
      margin: 0;
    }

    .btn {
      padding: $gap-sm ($gap-sm * 2);
    }
  }

  .files {
    border-top: solid 2px $input-border-color;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: solid 2px $input-border-color;
      font-size: 14px;
      line-height: normal;

      .file-info {
        display: flex;
        align-items: center;
        padding: $gap;

        .file-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .file-description {
          font-size: 13px;
          color: #aaa;
        }

        .file-type {
          i {
            width: 32px;
            text-align: center;
            font-size: 32px;
            color: #ccc;
            margin: 0 16px 0 6px;
          }
        }

        .f-progress {
          font-size: 16px;
          font-weight: $font-weight-semi-bold;
          color: #777;
          padding-right: 10px;
        }

        .remove {
          i {
            cursor: pointer;
            padding: 7px 10px;
            border-radius: 4px;

            &:hover {
              background: #eee;
              color: #000;
            }
          }
        }
      }

      &.has-error {
        .file-description {
          color: $danger;
        }

        .file-type {
          i {
            color: $danger;
          }
        }
      }

      &.completed {
        .file-type {
          i {
            color: $info;
          }
        }
      }

      &.uploading {
        border: none;
      }

      .f-progress-bar {
        height: 2px;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.f-input {
  display: none;
}
</style>
