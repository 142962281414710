<template>
  <app-card-wizard title="Evrak Yükleme">
    <p>
      <strong>{{ applicationData.approvalRequest.code }}</strong> numaralı
      <strong>{{ applicationData.companyInfo.companyName }}</strong> firması
      adına
      <template v-if="!isBrokerRegistration">
        <strong>{{
          applicationData.selectedAssociation.associationName
        }}</strong
        >'ne yapılan başvurunuzun
      </template>
      <template v-if="isBrokerRegistration">
        yapılan gümrük müşaviri başvurunuzun
      </template>
      tamamlanabilmesi için lütfen aşağıda belirtilen evrakları yükleyiniz.
    </p>
    <p>
      Yüklenecek dosyalar
      <strong class="text-danger">.pdf</strong>
      formatında ve dosya başına en fazla
      <strong class="text-danger">3MB</strong> olabilir olabilir.
    </p>
    <template v-if="applicationData.missingDocumentDescription">
      <div class="alert alert-info mt-4">
        {{ applicationData.missingDocumentDescription }}
      </div>
    </template>
    <div class="pt-5">
      <div
        :class="['uploader', { error: !!item.error }]"
        v-for="item in missingDocuments"
        :key="item"
      >
        <div class="title">{{ item.label }}</div>
        <div class="desc">{{ item.description }}</div>
        <app-file-upload
          :ref="'uploader_' + item.id"
          :id="item.id"
          placeholder="Yüklemek için dosya seçin"
          url="/upload"
          :auto="true"
          :multiple="true"
          :fileLimit="4"
          :maxFileSize="3000000"
          :allowedExtensions="['.pdf']"
          :formData="{
            ownerId: applicationData.approvalRequest.id,
            fileType: item.typeId
          }"
          @upload="onUpload"
          @remove="onRemove"
          @select="onSelect"
        ></app-file-upload>
        <div class="field-validation-error" v-if="item.error">
          {{ item.error }}
        </div>
      </div>
    </div>
    <div class="mt-5" v-if="isMissingDocumentRequest">
      <button
        type="button"
        :class="[
          'btn btn-primary d-flex align-items-center',
          { 'btn-loading': isLoading }
        ]"
        @click="onSubmit"
        :disabled="isLoading || !isValid"
      >
        <span>BAŞVURUYU TAMAMLA</span>
      </button>
    </div>
  </app-card-wizard>
  <app-card-wizard
    :title="
      isEsignRequired
        ? 'Belgeler e-imza ile imzalanmalıdır'
        : 'Belgeleri e-İmza İle İmzalamak İster misiniz?'
    "
    :showProgress="false"
    v-if="!isMissingDocumentRequest"
  >
    <template #description>
      <p v-if="!isEsignRequired">
        İşlemlerinizi daha hızlı ilerlemesi için yüklediğiniz belgelerin şirket
        yetkilisi tarafından kaşeli imzalı hallerini kargo ile göndermek yerine
        şirket yetkilisinizi e-imzası ile imzalayabilirsiniz. Eğer e-imza ile
        devam etmek isterseniziz aşağıdaki seçeniği seçerek başvurunuza devam
        edebilirsiniz. Bu seçeneği seçmeniz halinde başvurunuz alındıktan sonra
        başvuru sırasında belirttiğiniz
        <strong>{{ applicationData.email }}</strong>
        adresine belegeleri imzalayabileceğiniz uygulamanın adresi
        gönderielcektir. Gelen e-postada yer alan bu bağlantya tıklayarak
        belgeleri e-imza ile imzalayarak başvurunuzu hızlıca
        tamamlayabilirsiniz.
      </p>
      <p v-else>
        Başvuru yapmakta olduğunuz birlik sadece e-imza ila başvuru kabul
        etmektedir. Başvurunuz alındıktan sonra başvuru sırasında belirttiğiniz
        <strong>{{ applicationData.email }}</strong>
        adresine belegeleri e-imza ile imzalayabileceğiniz uygulamanın adresi
        gönderielcektir. Gelen e-postada yer alan bu bağlantya tıklayarak
        belgeleri e-imza ile imzalayarak başvurunuzu hızlıca
        tamamlayabilirsiniz.
      </p>
    </template>
    <div class="mt-5">
      <div class="d-flex align-items-center">
        <p-checkbox
          id="isEsignatureRequested"
          v-model="isEsignatureRequested"
          :binary="true"
          :disabled="isEsignRequired"
        ></p-checkbox>
        <label
          for="isEsignatureRequested"
          style="margin-bottom:0; margin-left:10px;"
        >
          Yüklediğim belgeleri şirket yetkilisinin e-imzası ile imzalayarak
          göndereceğim.
        </label>
      </div>
    </div>
    <div class="mt-5">
      <button
        type="button"
        :class="[
          'btn btn-primary d-flex align-items-center',
          { 'btn-loading': isLoading }
        ]"
        @click="onSubmit"
        :disabled="isLoading || !isValid"
      >
        <span>BAŞVURUYU TAMAMLA</span>
      </button>
    </div>
  </app-card-wizard>
</template>

<script>
//import PFileUpload from "primevue/fileupload";
import AppFileUpload from "../components/FileUpload.vue";
import { makeId } from "../shared";
import apiClient from "../services";
import PCheckbox from "primevue/checkbox";

export default {
  components: {
    PCheckbox,
    AppFileUpload
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      isEsignatureRequested: false,
      isEsignRequired: false,
      missingDocuments: [],
      uploadedFiles: []
    };
  },
  methods: {
    onSubmit() {
      this.validate();

      if (this.isValid) {
        this.showPageLoader("Başvurunuz tamamlanıyor...");
        this.isLoading = true;

        apiClient
          .complete(
            this.applicationData.approvalRequest.id,
            this.uploadedFiles,
            this.isEsignatureRequested
          )
          .then(response => {
            if (response && response.success) {
              this.markStepAsCompleted(this.stepName);
              this.updateApplicationData({
                files: this.uploadedFiles
              });

              this.goTo("/status/" + this.applicationData.stateId);
            } else {
              this.$swal({
                icon: "error",
                title: "Başvuru Alınamadı!",
                html:
                  "Başvurunuz kaydedilirken bir hata oluştu. <br /><br />" +
                  response.message,
                confirmButtonText: "Tamam"
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              title: "Başvuru Alınamadı!",
              html:
                "Başvurunuz kaydedilirken beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyin",
              confirmButtonText: "Tamam"
            });
          })
          .then(() => {
            this.isLoading = false;
            this.hidePageLoader();
          });
      }
    },
    onSelect() {
      //console.log(id, files, event);
    },
    onBeforeUpload() {
      // ignored
    },
    onUpload({ file }) {
      this.uploadedFiles.push(file.uploadResult.id);
      //console.log(id, file, files);
      this.validate();
    },
    onRemove({ file }) {
      if (
        file?.uploadResult?.id &&
        this.uploadedFiles.includes(file.uploadResult.id)
      ) {
        const index = this.uploadedFiles.indexOf(file.uploadResult.id);

        this.uploadedFiles.splice(index, 1);
      }

      //console.log(id, file, files);

      this.validate();
    },
    validate() {
      for (const d of this.missingDocuments) {
        const ref = this.$refs["uploader_" + d.id];

        this.isValid = true;
        d.error = null;

        if (ref.getUploadedFiles().length === 0) {
          d.error = "En az bir dosya yükleyin";
          this.isValid = false;
        }
      }
    }
  },
  computed: {
    stepName() {
      return "upload";
    },
    isMissingDocumentRequest() {
      const r = this.applicationData?.approvalRequest?.statusId == 2104;
      console.log(r);
      return r;
    }
  },
  beforeMount() {
    this.markStepAsInCompleted(this.stepName);

    this.missingDocuments = [...this.applicationData.missingDocuments].map(
      d => {
        return { ...d, id: makeId(), isUploaded: false };
      }
    );

    this.isEsignatureRequested = this.applicationData.isEsignRequested;

    apiClient
      .isEsignRequired(this.applicationData.selectedAssociation.id)
      .then(r => {
        const result = r?.data || false;
        this.isEsignatureRequested = result;
        this.isEsignRequired = result;
      })
      .catch(() => {
        this.isEsignRequired = false;
      });
  }
};
</script>

<style lang="scss">
@import "../scss/_mixins.scss";
@import "../scss/_variables.scss";

.uploader {
  margin-bottom: 40px;

  .title {
    color: $primary;
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  .desc {
    margin-bottom: 15px;
  }
}

.file-uploader.p-fileupload {
  .p-fileupload-content {
    padding: 15px;
  }

  .p-message {
    margin-bottom: 10px;
  }

  .p-fileupload-buttonbar {
    padding: 15px;
  }

  .p-fileupload-buttonbar {
    .p-button:not(.p-fileupload-choose) {
      display: none;
    }
  }
}
</style>
